import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EsimService } from '../esim.service';

@Component({
  selector: 'app-esim-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  qrCodeUrl: string = '';
  usageData: any = null;
  details: any;
  country: string = '';
  operator: string = '';
  installationGuide: any = null;
  selectedPlatform: 'android' | 'ios' | null = null;
  isLoading: boolean = false;
  isLoaded: boolean = false;

  constructor(
    private esimService: EsimService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    const esimDetails = history.state.data;
    if (esimDetails) {
      console.log(esimDetails);
      this.operator = esimDetails.operator;
      this.details = esimDetails.details;
      this.qrCodeUrl = esimDetails.details.qrcode_url;
      this.usageData = esimDetails.usage_data;
      this.fetchInstallationGuide(this.details.iccid);
    }
  }

  calculatePercentage(total: number | undefined, remaining: number | undefined): number {
    if (!total || !remaining) {
      return 0;
    }
    return ((total - remaining) / total) * 100;
  }

  // Convertir MB a GB
  convertDataUnits(value: number | null | undefined): string {
    if (!value) {
      return '0 MB';
    }

    if (value >= 1024) {
      const gbValue = (value / 1024).toFixed(2);
      return `${gbValue} GB`;
    } else {
      return `${value} MB`;
    }
  }



  fetchInstallationGuide(iccid: string): void {
    this.isLoading = true;
    this.esimService.getInstallationGuide(iccid).then(response => {
      if (response && response.data && response.data.instructions) {
        this.installationGuide = {
          ios: response.data.instructions.ios[0],
          android: response.data.instructions.android[0]
        };
      }
      this.isLoading = false;
    }).catch(error => {
      console.error('Error fetching installation guide:', error);
      this.isLoading = false;
    });
  }

  selectPlatform(platform: 'android' | 'ios'): void {
    this.selectedPlatform = platform;
  }

  goBack(): void {
    this.router.navigate(['/esim']);
  }

  onRecharge(iccid: string): void {
    this.router.navigate(['/topup', iccid]);
  }
}