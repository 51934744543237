/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "lambda",
            "endpoint": "https://rftl2ajl0h.execute-api.eu-central-1.amazonaws.com/production",
            "region": "eu-central-1"
        },
        {
            "name": "mailing",
            "endpoint": "https://on9r80qtxj.execute-api.eu-central-1.amazonaws.com/production",
            "region": "eu-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://o7qmwp2zefcqzpdtb3kg6wqrte.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ktltt4wttbca7gx6cub3c2xlt4",
    "aws_cognito_identity_pool_id": "eu-central-1:4eff3796-f702-46c6-9ab2-d541e3ff5396",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_Zb1tA9ePN",
    "aws_user_pools_web_client_id": "tqveg16a9k1f1golth562pt4v",
    "oauth": {
        "domain": "skylinkd5275e56-d5275e56-production.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://app.zendy.tel/",
        "redirectSignOut": "https://app.zendy.tel/logout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
