import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-languages',
  template: `
    <button (click)="openLanguages = !openLanguages">
      <div class="language flex uppercase items-center p-2 gap-1">
        <img width="16px" [src]="'./assets/icons/' + localeLanguage + '.svg'" />
        <span class="text-white font-bold text-xs">{{ localeLanguage }}</span>
      </div>
    </button>
    <div [hidden]="!openLanguages" class="absolute bg-white rounded-lg mt-1 cursor-pointer">
      <div
        *ngFor="let lang of languages"
        class="flex uppercase items-center p-2 gap-1"
        (click)="setLanguage(lang)"
      >
        <img width="16px" [src]="'./assets/icons/' + lang + '.svg'" />
        <span class="text-black font-bold text-xs">{{ lang }}</span>
      </div>
    </div>
  `,
  imports: [CommonModule],
  standalone: true,
  styles: [
    `
      .language {
        border-radius: 200px;
        background: rgba(255, 255, 255, 0.2);
      }
    `,
  ],
})
export class LanguageComponent implements OnInit {
  translateService = inject(TranslateService);
  localeLanguage!: string;
  openLanguages = false;
  languages = ['es', 'en'];
  ngOnInit() {
    this.localeLanguage = window.localStorage.getItem('_language') ?? 'en';
  }
  setLanguage(name: string) {
    window.localStorage.setItem('_language', name);
    this.localeLanguage = name;
    this.translateService.use(name);
    this.openLanguages = !this.openLanguages;
  }
}
