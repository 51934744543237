<h1 mat-dialog-title>Detalles del Item</h1>
<div mat-dialog-content>
  <div class="item-detail">
    <mat-icon>date_range</mat-icon>
    <label class="item-label">{{ 'COMPONENTS.LISTDETAIL.CALL_DATE' | translate }}:</label>
    <span class="item-value">{{ data.call_date }}</span>
  </div>
  <div class="item-detail">
    <mat-icon>call_made</mat-icon>
    <label class="item-label">{{ 'COMPONENTS.LISTDETAIL.CALLED_NUMBER' | translate }}:</label>
    <span class="item-value">{{ data.destination }}</span>
  </div>
  <div class="item-detail">
    <mat-icon>access_time</mat-icon>
    <label class="item-label">{{ 'COMPONENTS.LISTDETAIL.CALL_DURATION' | translate }}:</label>
    <span class="item-value">{{ data.total_time }}</span>
  </div>
  <div class="item-detail">
    <mat-icon>attach_money</mat-icon>
    <label class="item-label">{{ 'COMPONENTS.LISTDETAIL.CALL_CHARGE' | translate }}:</label>
    <span class="item-value">{{ data.amount }}</span>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{ 'COMPONENTS.LIST.CLOSE' | translate }}</button>
</div>
<style>
  .item-detail {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .item-detail mat-icon {
    margin-right: 8px;
    color: #C50B0F;
    vertical-align: middle;
  }
  
  .item-label {
    font-weight: bold;
    margin-right: 5px;
    color: #333;
    line-height: 1.5;
  }
  
  .item-value {
    color: #666; 
    line-height: 1.5;
    text-align: right;

  }
  
  .mat-dialog-title {
    font-size: 24px;
    color: #3f51b5;
    margin-bottom: 20px;
  }
  
  .mat-dialog-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  button[mat-button] {
    background-color: #C50B0F;
    color: #fff;
  }
  
  button[mat-button]:hover {
    background-color:#C50B0F; 
  }
</style>