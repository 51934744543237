import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { APIService, Contact } from 'src/app/API.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-new-contact',
  templateUrl: './new-contact.component.html',
})
export class NewContactComponent implements OnInit {
  contactId = this.route.snapshot.params['id'];
  telephone = this.route.snapshot.params['telephone'];
  createForm: FormGroup = this.fb.group({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.email]),
    telephone: new FormControl('', [
      Validators.required,
      Validators.pattern(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
      ),
    ]),
  });
  constructor(
    private fb: FormBuilder,
    private api: APIService,
    private authService: AuthService,
    private location: Location,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.contactId) {
      this.api.GetContact(this.contactId).then((data) => {
        this.createForm.patchValue(data);
      });
    } else if (this.telephone) {
      this.createForm.patchValue({
        telephone: this.telephone,
      });
    }
  }

  async onCreate() {
    const contact: Contact = {
      ...this.createForm.getRawValue(),
      owner: this.authService.getUser().username ?? '',
    };
    await this.api.CreateContact(contact).then(() => {
      this.goBack();
      this.createForm.reset();
    });
  }

  async onUpdate() {
    const contact: Contact = {
      ...this.createForm.getRawValue(),
      id: this.contactId,
    };
    await this.api.UpdateContact(contact).then(() => {
      this.goBack();
      this.createForm.reset();
    });
  }

  goBack() {
    this.location.back();
  }
}
