<h2 class="!mt-0">
  {{ 'COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.TITLE' | translate }} 
 @{{ user.username }} 
</h2>
<form [formGroup]="createForm">
  <mat-form-field class="calls-full-width">
    <mat-label>{{
      'COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.NAME' | translate
    }}</mat-label>
    <input
      formControlName="name"
      type="text"
      matInput
      placeholder="{{
        'COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.NAME' | translate
      }}"
    />
  </mat-form-field>
  <mat-form-field class="calls-full-width">
    <mat-label>{{
      'COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.EMAIL' | translate
    }}</mat-label>
    <input
      type="email"
      formControlName="email"
      matInput
      placeholder="example@example.com"
    />
  </mat-form-field>
  <mat-form-field class="calls-full-width">
    <mat-label>{{
      'COMPONENTS.PROFILE.MY_PROFILE.EDIT_PROFILE.PHONE' | translate
    }}</mat-label>
    
    <input
      ng-pattern="/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/"
      type="tel"
      formControlName="phone_number"
      matInput
      placeholder="+34555555123"
    />
  </mat-form-field>
  
  <div class="calls-full-width alertbg">
    {{ 'COMPONENTS.PROFILE.MY_PROFILE.VERIFY_MY_NUMBER.INFO' | translate }}
  </div>
  <div class="flex justify-between gap-4 mt-12">
    <app-button expand="full" color="secondary" (click)="goBack()">{{
      'COMMON.CANCEL' | translate
    }}</app-button>
    <app-button expand="full" (click)="onUpdate()">{{
      'COMMON.CONFIRM' | translate
    }}</app-button>
  </div>
</form>