<h2 *ngIf="!contactId" class="!mt-0">
  {{ 'CONTACTS.NEW.TITLE_ADD' | translate }}
</h2>
<h2 *ngIf="contactId" class="!mt-0">
  {{ 'CONTACTS.NEW.TITLE_EDIT' | translate }}
</h2>
<form [formGroup]="createForm">
  <mat-form-field class="calls-full-width">
    <mat-label>{{ 'CONTACTS.NEW.NAME' | translate }}</mat-label>
    <input
      formControlName="name"
      autofocus
      type="text"
      matInput
      placeholder="John Doe"
    />
  </mat-form-field>
  <mat-form-field class="calls-full-width">
    <mat-label>{{ 'CONTACTS.NEW.TELEPHONE' | translate }}</mat-label>
    <input
      autofocus
      ng-pattern="/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/"
      type="tel"
      formControlName="telephone"
      matInput
      placeholder="+34555555123"
    />
  </mat-form-field>
  <mat-form-field class="calls-full-width">
    <mat-label>{{ 'CONTACTS.NEW.EMAIL' | translate }}</mat-label>
    <input
      autofocus
      type="email"
      formControlName="email"
      matInput
      placeholder="example@example.com"
    />
  </mat-form-field>
  <div class="flex justify-between gap-4 mt-12">
    <app-button expand="full" color="secondary" (click)="goBack()">{{
      'COMMON.CANCEL' | translate
    }}</app-button>
    <app-button *ngIf="!contactId" expand="full" (click)="onCreate()" [disabled]="createForm.invalid">{{
      'CONTACTS.NEW.ADD_CONTACT' | translate
    }}</app-button>
    <app-button *ngIf="contactId" expand="full" (click)="onUpdate()" [disabled]="createForm.invalid">{{
      'COMMON.CONFIRM' | translate
    }}</app-button>
  </div>
</form>
